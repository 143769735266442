import React from 'react';
import { 
  ArrowLeft,
  Calendar,
  Brain,
  BarChart2,
  Users,
  Bot,
  Zap,
  Clock,
  Eye,
  Target,
  ChevronRight,
  LineChart,
  MessageSquare,
  Layout,
  Filter,
  Settings,
  Linkedin,
  Instagram,
  Twitter,
  Youtube,
  Facebook,
  CheckCircle
} from 'lucide-react';
import { useNavigate } from 'react-router-dom';

const FeaturesPage = () => {
  const navigate = useNavigate();

  
  const mainFeatures = [
    {
      icon: Brain,
      title: 'AI Content Generation',
      description: 'Generate engaging, platform-optimized content with our advanced AI system.',
      benefits: [
        'Platform-specific content optimization',
        'Engagement prediction',
        'Hashtag recommendations',
        'Tone and style customization'
      ]
    },
    {
      icon: Calendar,
      title: 'Smart Scheduling',
      description: 'Schedule posts at optimal times across multiple platforms.',
      benefits: [
        'Best time to post predictions',
        'Auto-queue optimization',
        'Bulk scheduling',
        'Cross-platform coordination'
      ]
    },
    {
      icon: BarChart2,
      title: 'Advanced Analytics',
      description: 'Comprehensive analytics and insights for data-driven decisions.',
      benefits: [
        'Real-time performance tracking',
        'Audience insights',
        'Competitive analysis',
        'Custom report builder'
      ]
    },
    {
      icon: Users,
      title: 'Team Collaboration',
      description: 'Streamline your team workflow with powerful collaboration tools.',
      benefits: [
        'Role-based access control',
        'Approval workflows',
        'Content calendar',
        'Team performance metrics'
      ]
    }
  ];

  const additionalFeatures = [
    {
      icon: Bot,
      title: 'Automation',
      description: 'Automate routine tasks and post scheduling'
    },
    {
      icon: Zap,
      title: 'Quick Actions',
      description: 'Perform common actions with keyboard shortcuts'
    },
    {
      icon: Clock,
      title: 'Time Zones',
      description: 'Schedule posts in different time zones'
    },
    {
      icon: Eye,
      title: 'Post Preview',
      description: 'Preview posts across different platforms'
    },
    {
      icon: Target,
      title: 'Audience Targeting',
      description: 'Target specific audience segments'
    },
    {
      icon: LineChart,
      title: 'Growth Tracking',
      description: 'Track follower growth and engagement'
    },
    {
      icon: MessageSquare,
      title: 'Comment Management',
      description: 'Manage and respond to comments'
    },
    {
      icon: Layout,
      title: 'Visual Planner',
      description: 'Plan your feed with visual grid layout'
    }
  ];

  const platformFeatures = [
    {
      platform: 'LinkedIn',
      icon: Linkedin,
      color: 'bg-[#0A66C2]',
      features: [
        'Company page management',
        'Article scheduling',
        'Professional content templates',
        'B2B targeting',
        'Analytics dashboard',
        'Lead generation tracking'
      ]
    },
    {
      platform: 'Instagram',
      icon: Instagram,
      color: 'bg-[#E4405F]',
      features: [
        'Story scheduling',
        'Carousel posts',
        'Visual feed planner',
        'Hashtag analytics',
        'Comment management',
        'Bio link optimization'
      ]
    },
    {
      platform: 'Twitter',
      icon: Twitter,
      color: 'bg-[#1DA1F2]',
      features: [
        'Thread creation',
        'Automated scheduling',
        'Engagement analytics',
        'Hashtag tracking',
        'Mention monitoring',
        'Performance metrics'
      ]
    },
    {
      platform: 'Facebook',
      icon: Facebook,
      color: 'bg-[#1877F2]',
      features: [
        'Page management',
        'Group posting',
        'Ad integration',
        'Event promotion',
        'Audience insights',
        'Content scheduling'
      ]
    }
  ];

  return (
    <div className="min-h-screen bg-gray-50">
      <nav className="bg-white border-b border-gray-100">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between h-16">
            <div className="flex-shrink-0 flex items-center">
              <span className="text-2xl font-bold text-blue-600">Socioblink</span>
            </div>
            <button 
              onClick={() => navigate('/')}
              className="flex items-center text-gray-600 hover:text-gray-900"
            >
              <ArrowLeft className="w-5 h-5 mr-2" />
              Back to Home
            </button>
          </div>
        </div>
      </nav>

      {/* Hero Section */}
      <div className="bg-gradient-to-r from-blue-600 to-indigo-600 text-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-24">
          <div className="text-center max-w-3xl mx-auto">
            <h1 className="text-4xl font-bold mb-6">
              All the Features You Need to Succeed
            </h1>
            <p className="text-xl opacity-90 mb-8">
              Powerful tools to help you manage, analyze, and grow your social media presence.
            </p>
            <button 
              onClick={() => navigate('/signup')}
              className="inline-flex items-center px-6 py-3 bg-white text-blue-600 rounded-lg hover:bg-gray-100 transition-colors"
            >
              Start Free Trial
              <ChevronRight className="w-5 h-5 ml-2" />
            </button>
          </div>
        </div>
      </div>

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
        {/* Main Features */}
        <div className="mb-24">
          <h2 className="text-3xl font-bold text-gray-900 text-center mb-16">
            Core Features
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
            {mainFeatures.map((feature, index) => (
              <div key={index} className="bg-white rounded-lg shadow-sm p-8">
                <feature.icon className="w-12 h-12 text-blue-600 mb-6" />
                <h3 className="text-2xl font-bold text-gray-900 mb-4">
                  {feature.title}
                </h3>
                <p className="text-gray-600 mb-6">
                  {feature.description}
                </p>
                <ul className="space-y-3">
                  {feature.benefits.map((benefit, idx) => (
                    <li key={idx} className="flex items-center text-gray-600">
                      <Zap className="w-5 h-5 text-blue-600 mr-3" />
                      {benefit}
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>

        {/* Platform-Specific Features */}
        <div className="mb-24">
          <h2 className="text-3xl font-bold text-gray-900 text-center mb-16">
            Platform Features
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            {platformFeatures.map((platform, index) => (
              <div key={index} className="bg-white rounded-lg shadow-sm overflow-hidden">
                <div className={`${platform.color} p-6 text-white`}>
                  <div className="flex items-center mb-4">
                    <platform.icon className="w-8 h-8 mr-3" />
                    <h3 className="text-xl font-bold">{platform.platform}</h3>
                  </div>
                </div>
                <div className="p-6">
                  <ul className="space-y-3">
                    {platform.features.map((feature, idx) => (
                      <li key={idx} className="flex items-center text-gray-600">
                        <CheckCircle className="w-5 h-5 text-green-500 mr-3" />
                        {feature}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Additional Features Grid */}
        <div>
          <h2 className="text-3xl font-bold text-gray-900 text-center mb-16">
            Additional Features
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
            {additionalFeatures.map((feature, index) => (
              <div key={index} className="bg-white p-6 rounded-lg shadow-sm">
                <feature.icon className="w-8 h-8 text-blue-600 mb-4" />
                <h3 className="text-lg font-semibold text-gray-900 mb-2">
                  {feature.title}
                </h3>
                <p className="text-gray-600">{feature.description}</p>
              </div>
            ))}
          </div>
        </div>

        {/* CTA Section */}
        <div className="mt-24 text-center">
          <h2 className="text-3xl font-bold text-gray-900 mb-6">
            Ready to Get Started?
          </h2>
          <p className="text-xl text-gray-600 mb-8 max-w-2xl mx-auto">
            Join thousands of businesses using Socioblink to grow their social media presence.
          </p>
          <div className="flex flex-col sm:flex-row justify-center gap-4">
            <button 
              onClick={() => navigate('/signup')}
              className="px-8 py-3 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
            >
              Start Free Trial
            </button>
            <button 
              onClick={() => navigate('/demo')}
              className="px-8 py-3 bg-gray-900 text-white rounded-lg hover:bg-gray-800 transition-colors"
            >
              Request Demo
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeaturesPage;