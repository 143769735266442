import React, { useState } from 'react';
import PostInput from './PostInput';
import PostToolbar from './PostToolbar';
import PostActions from './PostActions';
import PostPreview from './PostPreview';
import ScheduleDialog from './ScheduleDialog';
import AccountSelector from './AccountSelector';
import { Alert, AlertDescription } from './Alert';
import { useUser } from '../UserContext';
import { 
  PLATFORMS, 
  PLATFORM_LABELS, 
  VALIDATION, 
  POST_STATUS 
} from '../Constants';

// Add media validation constants to match backend constraints
const MEDIA_CONSTRAINTS = {
  IMAGE: {
    maxSize: 5 * 1024 * 1024, // 5MB
    formats: ['image/jpeg', 'image/png', 'image/gif'],
    dimensions: {
      min: { width: 400, height: 400 },
      max: { width: 4096, height: 4096 }
    }
  },
  VIDEO: {
    maxSize: 200 * 1024 * 1024, // 200MB
    formats: ['video/mp4'],
    duration: { max: 600 } // 10 minutes in seconds
  },
  PDF: {
    maxSize: 100 * 1024 * 1024, // 100MB
    formats: ['application/pdf']
  }
};

const CreatePost = ({ 
  onClose, 
  connectedAccounts = [],
  initialContent = '',
  initialImage = null,
  initialComment = '',
  initialSelectedAccounts = [],
  className = ''
}) => {
  const { user } = useUser();
  const [content, setContent] = useState(initialContent);
  const [selectedAccounts, setSelectedAccounts] = useState(initialSelectedAccounts);
  const [selectedImage, setSelectedImage] = useState(initialImage);
  const [firstComment, setFirstComment] = useState(initialComment);
  const [error, setError] = useState('');
  const [showScheduleDialog, setShowScheduleDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showAIGenerator, setShowAIGenerator] = useState(false);

    // Updated media validation function
    const validateMedia = (file) => {
      return new Promise((resolve, reject) => {
        if (!file) {
          reject(new Error('No file provided'));
          return;
        }
  
        // Handle both File objects and base64 strings
        if (file instanceof File) {
          // Handle File object
          const mediaType = getMediaTypeFromMimeType(file.type);
          validateFileObject(file, mediaType, resolve, reject);
        } else if (typeof file === 'string' && file.startsWith('data:')) {
          // Handle base64 string
          const mimeType = file.split(';')[0].split(':')[1];
          const mediaType = getMediaTypeFromMimeType(mimeType);
          validateBase64String(file, mediaType, resolve, reject);
        } else {
          reject(new Error('Invalid media format'));
        }
      });
    };
  
    // Helper function to get media type from MIME type
    const getMediaTypeFromMimeType = (mimeType) => {
      if (!mimeType) return null;
      
      if (mimeType.startsWith('image/')) return 'IMAGE';
      if (mimeType.startsWith('video/')) return 'VIDEO';
      if (mimeType === 'application/pdf') return 'PDF';
      return null;
    };
  
    // Validate File object
    const validateFileObject = (file, mediaType, resolve, reject) => {
      const constraints = MEDIA_CONSTRAINTS[mediaType];
  
      if (!constraints) {
        reject(new Error('Unsupported media type'));
        return;
      }
  
      // Check file size
      if (file.size > constraints.maxSize) {
        reject(new Error(`File size exceeds ${constraints.maxSize / (1024 * 1024)}MB limit`));
        return;
      }
  
      // Check format
      if (!constraints.formats.includes(file.type)) {
        reject(new Error(`Unsupported format. Allowed formats: ${constraints.formats.join(', ')}`));
        return;
      }
  
      // For images, check dimensions
      // if (mediaType === 'IMAGE') {
      //   const img = new Image();
      //   img.onload = () => {
      //     const { min, max } = constraints.dimensions;
      //     if (img.width < min.width || img.height < min.height) {
      //       reject(new Error(`Image dimensions must be at least ${min.width}x${min.height}`));
      //       return;
      //     }
      //     if (img.width > max.width || img.height > max.height) {
      //       reject(new Error(`Image dimensions must not exceed ${max.width}x${max.height}`));
      //       return;
      //     }
      //     resolve(true);
      //   };
      //   img.onerror = () => reject(new Error('Invalid image file'));
      //   img.src = URL.createObjectURL(file);
      // } else {
      //   resolve(true);
      // }
      resolve(true);
    };
  
    // Validate base64 string
    const validateBase64String = (base64String, mediaType, resolve, reject) => {
      const constraints = MEDIA_CONSTRAINTS[mediaType];
  
      if (!constraints) {
        reject(new Error('Unsupported media type'));
        return;
      }
  
      // Check format
      const mimeType = base64String.split(';')[0].split(':')[1];
      if (!constraints.formats.includes(mimeType)) {
        reject(new Error(`Unsupported format. Allowed formats: ${constraints.formats.join(', ')}`));
        return;
      }
  
      // Calculate base64 size
      const base64Length = base64String.split(',')[1].length;
      const fileSize = (base64Length * 3) / 4; // Approximate size in bytes
  
      if (fileSize > constraints.maxSize) {
        reject(new Error(`File size exceeds ${constraints.maxSize / (1024 * 1024)}MB limit`));
        return;
      }
  
      // For images, check dimensions
      // if (mediaType === 'IMAGE') {
      //   const img = new Image();
      //   img.onload = () => {
      //     const { min, max } = constraints.dimensions;
      //     if (img.width < min.width || img.height < min.height) {
      //       reject(new Error(`Image dimensions must be at least ${min.width}x${min.height}`));
      //       return;
      //     }
      //     if (img.width > max.width || img.height > max.height) {
      //       reject(new Error(`Image dimensions must not exceed ${max.width}x${max.height}`));
      //       return;
      //     }
      //     resolve(true);
      //   };
      //   img.onerror = () => reject(new Error('Invalid image file'));
      //   img.src = base64String;
      // } else {
      //   resolve(true);
      // }
      resolve(true);
    };

      // Updated handleImageUpload function
      const handleImageUpload = async (file) => {
        if (!file) {
          setSelectedImage(null);
          setError('');
          return;
        }
      
        try {
          // Determine file type from data URL
          const fileType = file.startsWith('data:image/') ? 'IMAGE' :
                          file.startsWith('data:video/') ? 'VIDEO' :
                          file.startsWith('data:application/pdf') ? 'PDF' : null;
      
          if (!fileType) {
            throw new Error('Unsupported file type');
          }
      
          const constraints = MEDIA_CONSTRAINTS[fileType];
          
          // Extract mime type and base64 data
          const [header, base64Data] = file.split(',');
          const mimeType = header.split(':')[1].split(';')[0];
      
          // Check format
          if (!constraints.formats.includes(mimeType)) {
            throw new Error(`Unsupported format. Allowed formats: ${constraints.formats.join(', ')}`);
          }
      
          // Check size
          const sizeInBytes = Math.ceil((base64Data.length * 3) / 4);
          if (sizeInBytes > constraints.maxSize) {
            throw new Error(`File size exceeds ${constraints.maxSize / (1024 * 1024)}MB limit`);
          }
      
          setSelectedImage(file);
          setError('');
        } catch (err) {
          console.error('Media validation error:', err);
          setError(err.message);
          setSelectedImage(null);
        }
      };

  const validatePost = () => {
    if (!content.trim()) {
      setError('Post content cannot be empty');
      return false;
    }

    if (selectedAccounts.length === 0) {
      setError('Please select at least one platform');
      return false;
    }

    // Check if LinkedIn is selected but not connected
    if (selectedAccounts.includes(PLATFORMS.LINKEDIN) && 
        (!user?.socialAccounts?.linkedin?.isConnected)) {
      setError('Please connect your LinkedIn account first');
      return false;
    }

    return true;
  };

  const handleSaveAsDraft = async () => {
    if (!validatePost()) return;

    setIsLoading(true);
    setError('');

    try {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('You must be logged in to create posts');
      }

      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/posts`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
          content: {
            text: content.trim(),
            media: selectedImage,
            firstComment: firstComment.trim()
          },
          platforms: selectedAccounts,
          isDraft: true
        })
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || errorData.details || 'Failed to save draft');
      }

      const data = await response.json();
      console.log('Draft saved successfully:', data);
      onClose();

    } catch (err) {
      console.error('Error saving draft:', err);
      setError(err.message || 'Failed to save draft. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleSchedulePost = async (scheduledDate, scheduledTime) => {
    if (!validatePost()) return;

    const scheduledDateTime = new Date(`${scheduledDate}T${scheduledTime}`);
    if (scheduledDateTime <= new Date()) {
      setError('Scheduled time must be in the future');
      return;
    }

    setIsLoading(true);
    setError('');

    try {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('You must be logged in to schedule posts');
      }

      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/posts`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
          content: {
            text: content.trim(),
            media: selectedImage,
            firstComment: firstComment.trim()
          },
          platforms: selectedAccounts,
          scheduledAt: scheduledDateTime.toISOString(),
          isDraft: false
        })
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || errorData.details || 'Failed to schedule post');
      }

      const data = await response.json();
      console.log('Post scheduled successfully:', data);
      onClose();

    } catch (err) {
      console.error('Error scheduling post:', err);
      setError(err.message || 'Failed to schedule post. Please try again.');
    } finally {
      setIsLoading(false);
      setShowScheduleDialog(false);
    }
  };
  
  return (
    <div className={`flex gap-4 ${className}`}>
      <div className="flex-1">
        <div className="bg-white rounded-lg shadow-sm p-6">
          <div className="flex items-center justify-between mb-6">
            <h2 className="text-xl font-semibold text-gray-900">Create Post</h2>
          </div>

          {error && (
            <Alert variant="destructive" className="mb-6">
              <AlertDescription>{error}</AlertDescription>
            </Alert>
          )}

          <AccountSelector
            accounts={connectedAccounts.map(account => ({
              id: account.toLowerCase(), // Ensure lowercase platform IDs
              label: PLATFORM_LABELS[account.toLowerCase()], // Use proper labels
              isConnected: user?.socialAccounts?.[account.toLowerCase()]?.isConnected || false
            }))}
          selectedAccounts={selectedAccounts}
          onAccountToggle={(account) => {
            setSelectedAccounts(prev => 
              prev.includes(account)
                ? prev.filter(a => a !== account)
                : [...prev, account]
            );
            setError('');
          }}
          onShowAIGenerator={() => setShowAIGenerator(true)}
        />

          {/* <PostInput
            content={content}
            onContentChange={(value) => {
              setContent(value);
              setError('');
            }}
            selectedImage={selectedImage}
            onImageChange={handleImageUpload}
            onImageRemove={() => setSelectedImage(null)}
            firstComment={firstComment}
            onFirstCommentChange={setFirstComment}
          /> */}

<PostInput
  content={content}
  onContentChange={setContent}
  selectedImage={selectedImage}
  onImageChange={handleImageUpload}
  firstComment={firstComment}
  onFirstCommentChange={setFirstComment}
  showAIGenerator={showAIGenerator}
  onShowAIGenerator={setShowAIGenerator}
/>

          <PostToolbar
            content={content}
            onContentChange={setContent}
            onImageUpload={handleImageUpload}
          />

          <PostActions
            isLoading={isLoading}
            onSaveDraft={handleSaveAsDraft}
            onSchedule={() => setShowScheduleDialog(true)}
            isDraftDisabled={!content.trim() || selectedAccounts.length === 0}
            isScheduleDisabled={!content.trim() || selectedAccounts.length === 0}
          />
        </div>
      </div>

      <PostPreview
        content={content}
        selectedImage={selectedImage}
        userProfile={{
          name: user?.name || user?.email,
          title: user?.company || 'Professional',
          avatar: null // Add user avatar handling if needed
        }}
      />

      {showScheduleDialog && (
        <ScheduleDialog
          onClose={() => setShowScheduleDialog(false)}
          onSchedule={handleSchedulePost}
          isLoading={isLoading}
          platforms={selectedAccounts}
        />
      )}
    </div>
  );
};

export default CreatePost;