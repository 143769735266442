import React from 'react';
import { ArrowLeft, Clock, User, Tag, ChevronRight } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

const BlogPage = () => {
  const navigate = useNavigate();

  const blogPosts = [
    {
      title: 'Best Times to Post on LinkedIn for Maximum Engagement',
      excerpt: 'Discover the optimal posting schedule for LinkedIn based on industry research and data analysis.',
      author: 'Sarah Johnson',
      date: 'December 10, 2024',
      readTime: '8 min read',
      category: 'LinkedIn Marketing',
      image: '/api/placeholder/600/400'
    },
    {
      title: 'Creating Engaging Instagram Reels: A Complete Guide',
      excerpt: 'Learn how to create scroll-stopping Reels that drive engagement and grow your following.',
      author: 'Emma Rodriguez',
      date: 'December 9, 2024',
      readTime: '10 min read',
      category: 'Instagram Marketing',
      image: '/api/placeholder/600/400'
    },
    {
      title: 'AI in Social Media: The Future of Content Creation',
      excerpt: 'Explore how artificial intelligence is revolutionizing social media content creation and management.',
      author: 'Michael Chen',
      date: 'December 8, 2024',
      readTime: '12 min read',
      category: 'AI & Technology',
      image: '/api/placeholder/600/400'
    }
  ];

  const categories = [
    'LinkedIn Marketing',
    'Instagram Marketing',
    'Content Strategy',
    'AI & Technology',
    'Social Media Analytics',
    'Growth Hacking'
  ];

  return (
    <div className="min-h-screen bg-gray-50">
      <nav className="bg-white border-b border-gray-100">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between h-16">
            <div className="flex-shrink-0 flex items-center">
              <span className="text-2xl font-bold text-blue-600">Socioblink</span>
            </div>
            <button 
              onClick={() => navigate('/')}
              className="flex items-center text-gray-600 hover:text-gray-900"
            >
              <ArrowLeft className="w-5 h-5 mr-2" />
              Back to Home
            </button>
          </div>
        </div>
      </nav>

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="flex flex-col lg:flex-row gap-8">
          {/* Main Content */}
          <div className="lg:w-2/3">
            <h1 className="text-3xl font-bold text-gray-900 mb-8">Latest Blog Posts</h1>
            <div className="space-y-8">
              {blogPosts.map((post, index) => (
                <article key={index} className="bg-white rounded-lg shadow-sm overflow-hidden">
                  <img
                    src={post.image}
                    alt={post.title}
                    className="w-full h-64 object-cover"
                  />
                  <div className="p-6">
                    <div className="flex items-center text-sm text-gray-500 mb-3">
                      <User className="w-4 h-4 mr-1" />
                      <span className="mr-4">{post.author}</span>
                      <Clock className="w-4 h-4 mr-1" />
                      <span className="mr-4">{post.date}</span>
                      <Tag className="w-4 h-4 mr-1" />
                      <span>{post.category}</span>
                    </div>
                    <h2 className="text-xl font-bold text-gray-900 mb-3">
                      {post.title}
                    </h2>
                    <p className="text-gray-600 mb-4">
                      {post.excerpt}
                    </p>
                    <button className="text-blue-600 font-medium flex items-center hover:text-blue-700">
                      Read More
                      <ChevronRight className="w-4 h-4 ml-1" />
                    </button>
                  </div>
                </article>
              ))}
            </div>
          </div>

          {/* Sidebar */}
          <div className="lg:w-1/3">
            {/* Categories */}
            <div className="bg-white rounded-lg shadow-sm p-6 mb-6">
              <h2 className="text-xl font-bold text-gray-900 mb-4">Categories</h2>
              <div className="space-y-2">
                {categories.map((category, index) => (
                  <button
                    key={index}
                    className="block w-full text-left px-3 py-2 rounded-lg hover:bg-gray-50 text-gray-600 hover:text-gray-900"
                  >
                    {category}
                  </button>
                ))}
              </div>
            </div>

            {/* Newsletter Signup */}
            <div className="bg-blue-600 rounded-lg shadow-sm p-6 text-white">
              <h2 className="text-xl font-bold mb-4">Stay Updated</h2>
              <p className="mb-4 opacity-90">
                Get the latest social media tips and strategies delivered to your inbox.
              </p>
              <input
                type="email"
                placeholder="Enter your email"
                className="w-full px-4 py-2 rounded-lg text-gray-900 mb-3"
              />
              <button className="w-full bg-white text-blue-600 px-4 py-2 rounded-lg font-medium hover:bg-gray-100 transition-colors">
                Subscribe
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogPage;