import React, { useState } from 'react';
import { 
    Wand2, 
    Copy, 
    RefreshCw, 
    X, 
    ChevronDown,
    CheckCircle,
    BarChart2,
    Hash,
    Lightbulb
  } from 'lucide-react';
  
  const AIPostGeneratorModal = ({ isOpen, onClose, onSelectPost }) => {
    const [formData, setFormData] = useState({
      topic: '',
      tone: '',
      industry: '',
      audience: '',
      platform: 'linkedin',
      contentType: 'educational',
      goals: ['engagement']
    });
    
    const [generatedPost, setGeneratedPost] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');
    const [activeTab, setActiveTab] = useState('main');
  
    const contentTypes = [
      { value: 'educational', label: 'Educational' },
      { value: 'storytelling', label: 'Storytelling' },
      { value: 'promotional', label: 'Promotional' }
    ];
  
    const goals = [
      { value: 'engagement', label: 'Increase Engagement' },
      { value: 'thought_leadership', label: 'Thought Leadership' },
      { value: 'brand_awareness', label: 'Brand Awareness' },
      { value: 'lead_generation', label: 'Lead Generation' }
    ];
  
    const handleChange = (e) => {
        const { name, value } = e.target;
        console.log(`Updating ${name} with value:`, value); // Debug log
        
        setFormData(prev => {
          const updated = {
            ...prev,
            [name]: value
          };
          console.log('Updated form data:', updated); // Debug log
          return updated;
        });
    };
  
    const handleGoalsChange = (goal) => {
      setFormData(prev => ({
        ...prev,
        goals: prev.goals.includes(goal)
          ? prev.goals.filter(g => g !== goal)
          : [...prev.goals, goal]
      }));
    };
  
    const [topic, setTopic] = useState('');
    const [tone, setTone] = useState('');
    const [industry, setIndustry] = useState('');
    const [audience, setAudience] = useState('');
  
    const handleGenerate = async () => {
        // Add console log to check form data
        console.log('Form data being submitted:', formData);
      
        // Check for empty required fields
        const requiredFields = ['topic', 'tone', 'industry', 'audience'];
        const emptyFields = requiredFields.filter(field => !formData[field].trim());
        
        if (emptyFields.length > 0) {
          setError(`Please fill in the following fields: ${emptyFields.join(', ')}`);
          return;
        }
      
        setIsLoading(true);
        setError('');
      
        try {
          const token = localStorage.getItem('token');
          const response = await fetch(`${process.env.REACT_APP_API_URL}/api/generate-post`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(formData)
          });
      
          if (!response.ok) {
            throw new Error('Failed to generate post');
          }
      
          const data = await response.json();
          console.log('Generated content:', data); // Add this log
          setGeneratedPost(data);
          setActiveTab('preview');
        } catch (err) {
          console.error('Error generating post:', err); // Add this log
          setError(err.message || 'Failed to generate post');
        } finally {
          setIsLoading(false);
        }
      };
  
    const handleCopy = () => {
      navigator.clipboard.writeText(generatedPost);
    };
  
    const handleUsePost = () => {
        // Ensure we're passing a string
        const postContent = typeof generatedPost.post === 'string' 
          ? generatedPost.post 
          : generatedPost.post?.content || '';
          
        console.log('Post content being passed:', postContent); // Debug log
        onSelectPost(postContent);
        onClose();
      };
  
    if (!isOpen) return null;
  
    const renderMetrics = () => {
      if (!generatedPost?.metrics) return null;
  
      return (
        <div className="grid grid-cols-2 gap-4 mb-6">
          {Object.entries(generatedPost.metrics).map(([metric, score]) => (
            <div key={metric} className="p-4 bg-gray-50 rounded-lg">
              <div className="text-sm text-gray-600 mb-1">
                {metric.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase())}
              </div>
              <div className="flex items-center space-x-2">
                <div className="text-2xl font-bold text-gray-900">{score}</div>
                <div 
                  className={`text-xs px-2 py-1 rounded-full ${
                    score >= 7 ? 'bg-green-100 text-green-800' : 
                    score >= 5 ? 'bg-yellow-100 text-yellow-800' : 
                    'bg-red-100 text-red-800'
                  }`}
                >
                  {score >= 7 ? 'Good' : score >= 5 ? 'Average' : 'Needs Work'}
                </div>
              </div>
            </div>
          ))}
        </div>
      );
    };
  
    const renderSuggestions = () => {
      if (!generatedPost?.suggestions?.length) return null;
  
      return (
        <div className="mb-6">
          <h3 className="text-sm font-medium text-gray-700 mb-2">Improvement Suggestions</h3>
          <div className="space-y-2">
            {generatedPost.suggestions.map((suggestion, index) => (
              <div key={index} className="flex items-start space-x-2 text-sm text-gray-600">
                <Lightbulb className="w-4 h-4 mt-0.5 text-yellow-500" />
                <span>{suggestion}</span>
              </div>
            ))}
          </div>
        </div>
      );
    };
  
    const renderHashtags = () => {
      if (!generatedPost?.hashtags?.length) return null;
  
      return (
        <div className="mb-6">
          <h3 className="text-sm font-medium text-gray-700 mb-2">Suggested Hashtags</h3>
          <div className="flex flex-wrap gap-2">
            {generatedPost.hashtags.map((hashtag, index) => (
              <button
                key={index}
                onClick={() => handleCopy(hashtag)}
                className="px-3 py-1 bg-blue-50 text-blue-600 rounded-full text-sm hover:bg-blue-100 transition-colors"
              >
                {hashtag}
              </button>
            ))}
          </div>
        </div>
      );
    };
  
    const renderTabs = () => (
      <div className="flex border-b mb-4">
        {['main', 'preview', 'variations'].map((tab) => (
          <button
            key={tab}
            onClick={() => setActiveTab(tab)}
            className={`px-4 py-2 text-sm font-medium ${
              activeTab === tab
                ? 'border-b-2 border-blue-500 text-blue-600'
                : 'text-gray-500 hover:text-gray-700'
            }`}
          >
            {tab.charAt(0).toUpperCase() + tab.slice(1)}
          </button>
        ))}
      </div>
    );
  
    const renderForm = () => (
      <div className="space-y-4">
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">Topic *</label>
          <input
            type="text"
            name="topic"
            value={formData.topic}
            onChange={handleChange}
            className="w-full p-2 border rounded-lg"
            placeholder="e.g., Digital Marketing Trends 2024"
          />
        </div>
  
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">Tone *</label>
          <select
            name="tone"
            value={formData.tone}
            onChange={handleChange}
            className="w-full p-2 border rounded-lg"
          >
            <option value="">Select tone</option>
            <option value="professional">Professional</option>
            <option value="casual">Casual</option>
            <option value="friendly">Friendly</option>
            <option value="authoritative">Authoritative</option>
          </select>
        </div>
  
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">Platform</label>
          <select
            name="platform"
            value={formData.platform}
            onChange={handleChange}
            className="w-full p-2 border rounded-lg"
          >
            <option value="linkedin">LinkedIn</option>
            <option value="instagram">Instagram</option>
          </select>
        </div>
  
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">Content Type</label>
          <select
            name="contentType"
            value={formData.contentType}
            onChange={handleChange}
            className="w-full p-2 border rounded-lg"
          >
            {contentTypes.map(type => (
              <option key={type.value} value={type.value}>{type.label}</option>
            ))}
          </select>
        </div>
  
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">Goals</label>
          <div className="grid grid-cols-2 gap-2">
            {goals.map(goal => (
              <button
                key={goal.value}
                onClick={() => handleGoalsChange(goal.value)}
                className={`p-2 text-sm rounded-lg border ${
                  formData.goals.includes(goal.value)
                    ? 'bg-blue-50 border-blue-200 text-blue-700'
                    : 'border-gray-200 text-gray-700 hover:bg-gray-50'
                }`}
              >
                {goal.label}
              </button>
            ))}
          </div>
        </div>
  
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">Industry *</label>
          <input
            type="text"
            name="industry"
            value={formData.industry}
            onChange={handleChange}
            className="w-full p-2 border rounded-lg"
            placeholder="e.g., Technology, Healthcare, Finance"
          />
        </div>
  
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">Target Audience *</label>
          <input
            type="text"
            name="audience"
            value={formData.audience}
            onChange={handleChange}
            className="w-full p-2 border rounded-lg"
            placeholder="e.g., B2B Professionals, Small Business Owners"
          />
        </div>
      </div>
    );
  
    if (!isOpen) return null;
  
    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center">
        <div className="bg-white rounded-lg max-w-2xl w-full mx-4 max-h-[90vh] flex flex-col">
          <div className="flex items-center justify-between p-4 border-b">
            <h2 className="text-lg font-semibold">Generate Post with AI</h2>
            <button onClick={onClose} className="p-2 hover:bg-gray-100 rounded-full">
              <X className="w-5 h-5" />
            </button>
          </div>
  
          <div className="p-4 overflow-y-auto">
            {error && (
              <div className="mb-4 p-3 text-sm rounded-lg bg-red-100 text-red-800">
                {error}
              </div>
            )}
  
            {renderTabs()}
  
            {activeTab === 'main' && renderForm()}
  
            {activeTab === 'preview' && generatedPost && (
              <>
                {renderMetrics()}
                {renderSuggestions()}
                {renderHashtags()}
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Generated Post
                  </label>
                  <div className="relative">
                    <textarea
                      value={generatedPost.post}
                      readOnly
                      className="w-full p-3 border rounded-lg min-h-[200px]"
                    />
                    <button
                      onClick={() => handleCopy(generatedPost.post)}
                      className="absolute top-2 right-2 p-2 hover:bg-gray-100 rounded-lg"
                      title="Copy to clipboard"
                    >
                      <Copy className="w-4 h-4" />
                    </button>
                  </div>
                </div>
              </>
            )}
  
            {activeTab === 'variations' && generatedPost?.variations && (
              <div className="space-y-4">
                {generatedPost.variations.map((variation, index) => (
                  <div key={index} className="p-4 border rounded-lg">
                    <div className="flex justify-between items-center mb-2">
                      <h3 className="text-sm font-medium text-gray-700">Variation {index + 1}</h3>
                      <button
                        onClick={() => handleCopy(variation.content)}
                        className="p-1 hover:bg-gray-100 rounded-lg"
                      >
                        <Copy className="w-4 h-4" />
                      </button>
                    </div>
                    <p className="text-sm text-gray-600">{variation.content}</p>
                  </div>
                ))}
              </div>
            )}
          </div>
  
          <div className="flex justify-end space-x-3 p-4 border-t">
            <button
              onClick={onClose}
              className="px-4 py-2 text-gray-700 bg-white border rounded-lg hover:bg-gray-50"
            >
              Cancel
            </button>
            {activeTab === 'main' ? (
              <button
                onClick={handleGenerate}
                disabled={isLoading}
                className="px-4 py-2 text-white bg-blue-500 rounded-lg hover:bg-blue-600 disabled:opacity-50 flex items-center"
              >
                {isLoading ? (
                  <>
                    <RefreshCw className="w-4 h-4 mr-2 animate-spin" />
                    Generating...
                  </>
                ) : (
                  <>
                    <Wand2 className="w-4 h-4 mr-2" />
                    Generate Post
                  </>
                )}
              </button>
            ) : (
              <button
                onClick={handleUsePost}
                className="px-4 py-2 text-white bg-blue-500 rounded-lg hover:bg-blue-600 flex items-center"
              >
                <CheckCircle className="w-4 h-4 mr-2" />
                Use This Post
              </button>
            )}
          </div>
        </div>
      </div>
    );
  };
  
  export default AIPostGeneratorModal;