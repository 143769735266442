import React, { useState, useEffect } from 'react';
import { 
  ArrowLeft, 
  Search, 
  BookOpen, 
  MessageSquare, 
  PlayCircle, 
  FileText, 
  ChevronRight,
  ChevronDown,
  Lightbulb,
  LifeBuoy,
  Mail,
  Clock
} from 'lucide-react';
import { useNavigate } from 'react-router-dom';

const HelpCenter = () => {
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [expandedFaq, setExpandedFaq] = useState(null);

  const categories = [
    {
      id: 'getting-started',
      icon: BookOpen,
      title: 'Getting Started',
      description: 'Learn the basics of using Socioblink',
      articles: [
        {
          id: 1,
          title: 'Quick Start Guide',
          content: 'Learn how to set up your account and start posting in minutes.',
          readTime: '5 min read',
          views: '2.5k'
        },
        {
          id: 2,
          title: 'Connecting Social Accounts',
          content: 'Step-by-step guide to linking your social media profiles.',
          readTime: '3 min read',
          views: '1.8k'
        },
        {
          id: 3,
          title: 'Creating Your First Post',
          content: 'Learn the basics of creating and scheduling posts.',
          readTime: '4 min read',
          views: '3.2k'
        }
      ]
    },
    {
      id: 'scheduling',
      icon: Clock,
      title: 'Post Scheduling',
      description: 'Master post scheduling and timing',
      articles: [
        {
          id: 4,
          title: 'Scheduling Best Practices',
          content: 'Learn how to optimize your posting schedule.',
          readTime: '6 min read',
          views: '4.1k'
        },
        {
          id: 5,
          title: 'Bulk Scheduling Guide',
          content: 'Schedule multiple posts efficiently.',
          readTime: '7 min read',
          views: '2.9k'
        },
        {
          id: 6,
          title: 'Time Zone Management',
          content: 'Handle posting across different time zones.',
          readTime: '4 min read',
          views: '1.5k'
        }
      ]
    },
    {
      id: 'analytics',
      icon: FileText,
      title: 'Analytics & Reporting',
      description: 'Understand your social media performance',
      articles: [
        {
          id: 7,
          title: 'Understanding Analytics Dashboard',
          content: 'Learn to interpret your performance metrics.',
          readTime: '8 min read',
          views: '3.7k'
        },
        {
          id: 8,
          title: 'Custom Report Creation',
          content: 'Build tailored reports for your needs.',
          readTime: '6 min read',
          views: '2.2k'
        },
        {
          id: 9,
          title: 'Engagement Metrics Guide',
          content: 'Deep dive into engagement analytics.',
          readTime: '5 min read',
          views: '2.8k'
        }
      ]
    }
  ];

  const faqs = [
    {
      question: 'How do I connect my social media accounts?',
      answer: 'Go to Settings > Connected Accounts and click on the social media platform you want to connect. Follow the authentication process for each platform. You can connect multiple accounts from the same platform.'
    },
    {
      question: 'Can I schedule posts for multiple platforms at once?',
      answer: 'Yes! When creating a post, you can select multiple platforms and customize the content for each one. The post will be scheduled to publish on all selected platforms at the specified time.'
    },
    {
      question: 'What happens if my scheduled post fails to publish?',
      answer: "If a post fails to publish, you'll receive an immediate notification. The post will be moved to your Failed Posts queue where you can review the error, make necessary adjustments, and reschedule."
    },
    {
      question: 'How do I add team members to my account?',
      answer: "Navigate to Settings > Team Management and click Add Team Member. Enter their email address and assign their role. They'll receive an invitation to join your workspace."
    }
  ];

  const filterArticles = (articles) => {
    if (!searchQuery) return articles;
    return articles.filter(article =>
      article.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
      article.content.toLowerCase().includes(searchQuery.toLowerCase())
    );
  };

  return (
    <div className="min-h-screen bg-gray-50">
      <nav className="bg-white border-b border-gray-100">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between h-16">
            <div className="flex-shrink-0 flex items-center">
              <span className="text-2xl font-bold text-blue-600">Socioblink</span>
            </div>
            <button 
              onClick={() => navigate('/')}
              className="flex items-center text-gray-600 hover:text-gray-900"
            >
              <ArrowLeft className="w-5 h-5 mr-2" />
              Back to Home
            </button>
          </div>
        </div>
      </nav>

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        {/* Search Section */}
        <div className="text-center mb-16">
          <h1 className="text-4xl font-bold text-gray-900 mb-6">How can we help you?</h1>
          <div className="max-w-2xl mx-auto relative">
            <Search className="absolute left-4 top-1/2 transform -translate-y-1/2 text-gray-400 w-5 h-5" />
            <input
              type="text"
              placeholder="Search for help articles..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="w-full pl-12 pr-4 py-3 rounded-lg border border-gray-200 focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
        </div>

        {/* Quick Help */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-16">
          <button className="flex items-center p-6 bg-white rounded-lg shadow-sm hover:shadow-md transition-shadow">
            <Lightbulb className="w-8 h-8 text-blue-600 mr-4" />
            <div className="text-left">
              <h3 className="text-lg font-semibold text-gray-900">Knowledge Base</h3>
              <p className="text-gray-600">Browse our comprehensive guides</p>
            </div>
          </button>

          <button className="flex items-center p-6 bg-white rounded-lg shadow-sm hover:shadow-md transition-shadow">
            <LifeBuoy className="w-8 h-8 text-blue-600 mr-4" />
            <div className="text-left">
              <h3 className="text-lg font-semibold text-gray-900">Live Chat</h3>
              <p className="text-gray-600">Chat with our support team</p>
            </div>
          </button>

          <button className="flex items-center p-6 bg-white rounded-lg shadow-sm hover:shadow-md transition-shadow">
            <Mail className="w-8 h-8 text-blue-600 mr-4" />
            <div className="text-left">
              <h3 className="text-lg font-semibold text-gray-900">Email Support</h3>
              <p className="text-gray-600">Get help via email</p>
            </div>
          </button>
        </div>

        {/* Categories and Articles */}
        <div className="grid md:grid-cols-3 gap-8 mb-16">
          {/* Categories */}
          <div className="md:col-span-1">
            <h2 className="text-xl font-bold text-gray-900 mb-4">Categories</h2>
            <div className="space-y-2">
              {categories.map((category) => (
                <button
                  key={category.id}
                  onClick={() => setSelectedCategory(category)}
                  className={`w-full text-left p-3 rounded-lg transition-colors ${
                    selectedCategory?.id === category.id
                      ? 'bg-blue-50 text-blue-600'
                      : 'text-gray-600 hover:bg-gray-50'
                  }`}
                >
                  <div className="flex items-center">
                    <category.icon className="w-5 h-5 mr-3" />
                    {category.title}
                  </div>
                </button>
              ))}
            </div>
          </div>

          {/* Articles */}
          <div className="md:col-span-2">
            <div className="bg-white rounded-lg shadow-sm p-6">
              <h2 className="text-xl font-bold text-gray-900 mb-6">
                {selectedCategory ? selectedCategory.title : 'Popular Articles'}
              </h2>
              <div className="space-y-6">
                {(selectedCategory ? filterArticles(selectedCategory.articles) : filterArticles(categories[0].articles)).map((article) => (
                  <article
                    key={article.id}
                    className="border-b border-gray-100 last:border-0 pb-6 last:pb-0"
                  >
                    <h3 className="text-lg font-semibold text-gray-900 mb-2">
                      {article.title}
                    </h3>
                    <p className="text-gray-600 mb-3">{article.content}</p>
                    <div className="flex items-center text-sm text-gray-500">
                      <Clock className="w-4 h-4 mr-1" />
                      <span className="mr-4">{article.readTime}</span>
                      <span>{article.views} views</span>
                    </div>
                  </article>
                ))}
              </div>
            </div>
          </div>
        </div>

        {/* FAQs */}
        <div>
          <h2 className="text-2xl font-bold text-gray-900 mb-8">Frequently Asked Questions</h2>
          <div className="space-y-4">
            {faqs.map((faq, index) => (
              <div key={index} className="bg-white rounded-lg shadow-sm">
                <button
                  onClick={() => setExpandedFaq(expandedFaq === index ? null : index)}
                  className="w-full px-6 py-4 flex items-center justify-between text-left"
                >
                  <span className="font-semibold text-gray-900">{faq.question}</span>
                  <ChevronDown
                    className={`w-5 h-5 text-gray-500 transition-transform ${
                      expandedFaq === index ? 'transform rotate-180' : ''
                    }`}
                  />
                </button>
                {expandedFaq === index && (
                  <div className="px-6 pb-4">
                    <p className="text-gray-600">{faq.answer}</p>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HelpCenter;