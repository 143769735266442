// import React from 'react';
// import { Link, useNavigate } from 'react-router-dom';
// import { 
//   Robot, 
//   Sparkles, 
//   Brain, 
//   BarChart2, 
//   Rocket,
//   Clock,
//   ArrowRight,
//   Calendar,
//   Settings,
//   Zap,
//   RefreshCcw,
//   TrendingUp,
//   Target
// } from 'lucide-react';

// const HomePage = () => {
//   const navigate = useNavigate();

//   const handleSignUp = (e) => {
//     e.preventDefault();
//     navigate('/auth');
//   };

//   const painPoints = [
//     {
//       icon: Clock,
//       title: 'Time-Consuming Content Creation',
//       description: 'Hours spent brainstorming, creating, and managing posts across platforms'
//     },
//     {
//       icon: RefreshCcw,
//       title: 'Algorithm Changes',
//       description: 'Constant struggle to keep up with platform updates and best practices'
//     },
//     {
//       icon: BarChart2,
//       title: 'Complex Analytics',
//       description: 'Difficulty understanding performance metrics and making data-driven decisions'
//     },
//     {
//       icon: Target,
//       title: 'Inconsistent Growth',
//       description: 'Unpredictable results and engagement across different platforms'
//     }
//   ];

//   const solutions = [
//     {
//       icon: Robot,
//       title: 'AI Content Creation',
//       description: 'Automated content generation that matches your brand voice and platform requirements'
//     },
//     {
//       icon: Brain,
//       title: 'Algorithm Intelligence',
//       description: 'Real-time platform analysis and optimization recommendations'
//     },
//     {
//       icon: Sparkles,
//       title: 'Smart Scheduling',
//       description: 'AI-powered posting times for maximum engagement'
//     },
//     {
//       icon: TrendingUp,
//       title: 'Growth Analytics',
//       description: 'Clear, actionable insights to drive consistent growth'
//     }
//   ];

//   return (
//     <div className="min-h-screen bg-white">
//       {/* Navigation */}
//       <nav className="bg-white border-b border-gray-100">
//         <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
//           <div className="flex justify-between h-16">
//             <div className="flex-shrink-0 flex items-center">
//               <span className="text-2xl font-bold text-blue-600">Socioblink</span>
//             </div>
//             <div className="flex items-center space-x-4">
//               <button onClick={handleSignUp} className="px-4 py-2 text-blue-600 font-medium">
//                 Log in
//               </button>
//               <button 
//                 onClick={handleSignUp}
//                 className="px-4 py-2 bg-blue-600 text-white rounded-lg font-medium hover:bg-blue-700 transition-colors"
//               >
//                 Start Free Trial
//               </button>
//             </div>
//           </div>
//         </div>
//       </nav>

//       {/* Hero Section */}
//       <div className="bg-gradient-to-b from-blue-50 to-white">
//         <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-24">
//           <div className="max-w-4xl mx-auto text-center">
//             <div className="inline-flex items-center bg-blue-100 text-blue-600 rounded-full px-4 py-2 mb-6">
//               <Rocket className="w-4 h-4 mr-2" />
//               The Future of Social Media Management
//             </div>
//             <h1 className="text-5xl font-bold text-gray-900 mb-6">
//               Replace Your Social Media Team With AI
//             </h1>
//             <p className="text-xl text-gray-600 mb-8">
//               Let AI handle your entire social media presence - from content creation to analytics. 
//               Get consistent growth across all platforms without the manual work.
//             </p>
//             <div className="flex flex-col items-center space-y-4">
//               <button
//                 onClick={handleSignUp}
//                 className="px-8 py-4 bg-blue-600 text-white rounded-lg font-medium hover:bg-blue-700 transition-colors flex items-center"
//               >
//                 Transform Your Social Media
//                 <ArrowRight className="ml-2 w-5 h-5" />
//               </button>
//               <div className="flex items-center text-gray-500 text-sm">
//                 <Calendar className="w-4 h-4 mr-2" />
//                 14-day free trial • No credit card required
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>

//       {/* Problem Section */}
//       <div className="py-20 bg-white">
//         <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
//           <div className="text-center mb-16">
//             <h2 className="text-4xl font-bold text-gray-900 mb-4">
//               The Problem With Social Media Management
//             </h2>
//             <p className="text-xl text-gray-600 max-w-2xl mx-auto">
//               Managing social media shouldn't be a full-time job
//             </p>
//           </div>
//           <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
//             {painPoints.map((point, index) => (
//               <div key={index} className="bg-gray-50 p-8 rounded-xl">
//                 <div className="w-12 h-12 bg-red-100 rounded-lg flex items-center justify-center mb-6">
//                   <point.icon className="w-6 h-6 text-red-600" />
//                 </div>
//                 <h3 className="text-xl font-semibold text-gray-900 mb-3">
//                   {point.title}
//                 </h3>
//                 <p className="text-gray-600">
//                   {point.description}
//                 </p>
//               </div>
//             ))}
//           </div>
//         </div>
//       </div>

//       {/* Solution Section */}
//       <div className="py-20 bg-gray-50">
//         <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
//           <div className="text-center mb-16">
//             <h2 className="text-4xl font-bold text-gray-900 mb-4">
//               The AI Solution
//             </h2>
//             <p className="text-xl text-gray-600 max-w-2xl mx-auto">
//               Let artificial intelligence handle your entire social media presence
//             </p>
//           </div>
//           <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
//             {solutions.map((solution, index) => (
//               <div key={index} className="bg-white p-8 rounded-xl shadow-sm hover:shadow-md transition-shadow">
//                 <div className="w-12 h-12 bg-blue-100 rounded-lg flex items-center justify-center mb-6">
//                   <solution.icon className="w-6 h-6 text-blue-600" />
//                 </div>
//                 <h3 className="text-xl font-semibold text-gray-900 mb-3">
//                   {solution.title}
//                 </h3>
//                 <p className="text-gray-600">
//                   {solution.description}
//                 </p>
//               </div>
//             ))}
//           </div>
//         </div>
//       </div>

//       {/* CTA Section */}
//       <div className="bg-blue-600 text-white">
//         <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-20">
//           <div className="text-center">
//             <h2 className="text-3xl font-bold mb-6">
//               Ready to Automate Your Social Media?
//             </h2>
//             <p className="text-xl opacity-90 mb-8">
//               Join the AI revolution in social media management
//             </p>
//             <button
//               onClick={handleSignUp}
//               className="px-8 py-4 bg-white text-blue-600 rounded-lg font-medium hover:bg-gray-100 transition-colors inline-flex items-center"
//             >
//               Start Your Free Trial
//               <ArrowRight className="ml-2 w-5 h-5" />
//             </button>
//           </div>
//         </div>
//       </div>

//       {/* Footer */}
//       <footer className="bg-white py-12 border-t border-gray-100">
//     <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
//       <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
//         <div>
//           <h3 className="text-sm font-semibold text-gray-900 tracking-wider uppercase mb-4">
//             Company
//           </h3>
//           <ul className="space-y-3">
//             <li>
//               <Link to="/about" className="text-base text-gray-500 hover:text-gray-900">
//                 About Us
//               </Link>
//             </li>
//             <li>
//               <Link to="/contact" className="text-base text-gray-500 hover:text-gray-900">
//                 Contact
//               </Link>
//             </li>
//             <li>
//               <Link to="/careers" className="text-base text-gray-500 hover:text-gray-900">
//                 Careers
//               </Link>
//             </li>
//           </ul>
//         </div>

//         <div>
//           <h3 className="text-sm font-semibold text-gray-900 tracking-wider uppercase mb-4">
//             Product
//           </h3>
//           <ul className="space-y-3">
//             <li>
//               <Link to="/features" className="text-base text-gray-500 hover:text-gray-900">
//                 Features
//               </Link>
//             </li>
//             <li>
//               <Link to="/pricing" className="text-base text-gray-500 hover:text-gray-900">
//                 Pricing
//               </Link>
//             </li>
//             {/* <li>
//               <Link to="/integrations" className="text-base text-gray-500 hover:text-gray-900">
//                 Integrations
//               </Link>
//             </li> */}
//           </ul>
//         </div>

//         <div>
//           <h3 className="text-sm font-semibold text-gray-900 tracking-wider uppercase mb-4">
//             Resources
//           </h3>
//           <ul className="space-y-3">
//             <li>
//               <Link to="/blog" className="text-base text-gray-500 hover:text-gray-900">
//                 Blog
//               </Link>
//             </li>
//             <li>
//               <Link to="/help" className="text-base text-gray-500 hover:text-gray-900">
//                 Help Center
//               </Link>
//             </li>
//             {/* <li>
//               <Link to="/guides" className="text-base text-gray-500 hover:text-gray-900">
//                 Guides
//               </Link>
//             </li> */}
//           </ul>
//         </div>

//         <div>
//           <h3 className="text-sm font-semibold text-gray-900 tracking-wider uppercase mb-4">
//             Legal
//           </h3>
//           <ul className="space-y-3">
//             <li>
//               <Link to="/privacy" className="text-base text-gray-500 hover:text-gray-900">
//                 Privacy Policy
//               </Link>
//             </li>
//             {/* <li>
//               <Link to="/terms" className="text-base text-gray-500 hover:text-gray-900">
//                 Terms of Service
//               </Link>
//             </li>
//             <li>
//               <Link to="/security" className="text-base text-gray-500 hover:text-gray-900">
//                 Security
//               </Link>
//             </li> */}
//           </ul>
//         </div>
//       </div>

//       <div className="mt-8 pt-8 border-t border-gray-100">
//         <p className="text-base text-gray-400">
//           © {new Date().getFullYear()} Socioblink. All rights reserved.
//         </p>
//       </div>
//     </div>
//   </footer>

//       {/* Font Style */}
//       <style jsx global>{`
//         @import url('https://fonts.googleapis.com/css2?family=Caveat:wght@700&display=swap');

//         .font-handwritten {
//           font-family: 'Caveat', cursive;
//           font-size: 1.2em;
//           padding: 0 0.2em;
//         }
//       `}</style>
//     </div>
//   );
// };

// export default HomePage;

import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { 
  Zap, 
  TrendingUp, 
  Brain, 
  BarChart2, 
  Users, 
  Palette,
  ArrowRight,
  CheckCircle,
  Calendar,
  Clock,
  DollarSign,
  LineChart,
  AlignCenter,
  Footprints
} from 'lucide-react';

const HomePage = () => {
  const navigate = useNavigate();

  const handleSignUp = (e) => {
    e.preventDefault();
    navigate('/auth');
  };

  const features = [
    { 
      icon: Brain,
      title: 'Platform Algorithm Insights',
      description: 'Stay ahead with AI-powered insights that adapt to platform changes in real-time'
    },
    {
      icon: Zap,
      title: 'AI Content Generation',
      description: 'Create engaging, platform-optimized content that resonates with your audience'
    },
    {
      icon: BarChart2,
      title: 'Advanced Analytics',
      description: 'Make data-driven decisions with comprehensive performance metrics'
    },
    {
      icon: Users,
      title: 'Team Collaboration',
      description: 'Streamline your workflow with integrated team management tools'
    },
    {
      icon: Palette,
      title: 'Custom Branding',
      description: 'Maintain consistent brand identity across all social platforms'
    }
  ];

  const testimonials = [
    { name: 'Jared Davidson', role: 'Influencer', comment: 'SocioPot has revolutionized my content strategy!' },
    { name: 'Aditi Singhal', role: 'Marketing Manager', comment: 'The analytics tools are a game-changer for our brand.' },
    { name: 'Rodolfo Roca', role: 'Small Business Owner', comment: 'I\'ve seen a 200% increase in engagement since using SocioPot.' }
  ];

  const challenges = [
    {
      icon: Clock,
      title: 'Traditional Approach',
      description: 'Months of waiting for results',
      solution: 'Results in Weeks'
    },
    {
      icon: LineChart,
      title: 'Inconsistent Performance',
      description: 'Unpredictable social media growth',
      solution: 'Steady Growth Path'
    },
    {
      icon: BarChart2,
      title: 'Complex Analytics',
      description: 'Overwhelming data without insights',
      solution: 'Clear, Actionable Insights'
    },
    {
      icon: Footprints,
      title: 'Outdated Strategies',
      description: 'Unable to keep up with algorithm changes',
      solution: 'AI-Powered Adaptation'
    },
    {
      icon: DollarSign,
      title: 'High Costs',
      description: 'Expensive social media teams',
      solution: 'Cost-Effective Solution'
    }
  ];

  const StarIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="h-5 w-5">
      <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon>
    </svg>
  );

  return (
    <div className="min-h-screen bg-white">
      {/* Navigation */}
      <nav className="bg-white border-b border-gray-100">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between h-16">
            <div className="flex-shrink-0 flex items-center">
              <span className="text-2xl font-bold text-blue-600">Socioblink</span>
            </div>
            <div className="flex items-center space-x-4">
              <button onClick={handleSignUp} className="px-4 py-2 text-blue-600 font-medium">
                Log in
              </button>
              <button 
                onClick={handleSignUp}
                className="px-4 py-2 bg-blue-600 text-white rounded-lg font-medium hover:bg-blue-700 transition-colors"
              >
                Start Free Trial
              </button>
            </div>
          </div>
        </div>
      </nav>

      {/* Hero Section */}
      <div className="bg-gradient-to-r from-blue-50 to-indigo-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-24 text-center">
          <div className="max-w-4xl mx-auto">
          <h1 className="text-5xl font-bold text-gray-900 mb-6">
  Drive Social Media Growth in{' '}
  <div className="relative inline-flex items-center">
    <div className="relative">
      <span className="relative z-10 font-handwritten">Months</span>
      <span className="absolute left-0 right-0 top-1/2 h-1 bg-red-500 transform -rotate-6" 
        style={{ width: '100%' }} // Constrains the line to the width of "Months"
      />
    </div>
    <span className="text-blue-600 ml-2 font-handwritten">Weeks</span>
  </div>
</h1>
            <p className="text-xl text-gray-600 mb-8">
              Your AI-powered social media manager that delivers exponential growth 
              through smart insights, advanced analytics, and platform-optimized content.
            </p>
            <div className="flex flex-col items-center justify-center space-y-4">
  <button
    onClick={handleSignUp}
    className="px-8 py-4 bg-blue-600 text-white rounded-lg font-medium hover:bg-blue-700 transition-colors flex items-center"
  >
    Get Started Free
    <ArrowRight className="ml-2 w-5 h-5" />
  </button>
  <div className="flex items-center text-gray-500 text-sm">
    <Calendar className="w-4 h-4 mr-2" />
    14-day free trial, no credit card required
  </div>
</div>
          </div>
        </div>
      </div>

      {/* Challenges Section */}
      <div className="py-20 bg-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-16">
            <h2 className="text-4xl font-bold text-gray-900 mb-4">
              Transform Your Social Media Strategy
            </h2>
            <p className="text-xl text-gray-600 max-w-2xl mx-auto">
              Leave behind traditional challenges and embrace the future of social media management
            </p>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {challenges.map((item, index) => (
              <div 
                key={index}
                className="group relative p-6 bg-white rounded-xl shadow-sm hover:shadow-md transition-all duration-300 overflow-hidden"
              >
                <div className="absolute inset-0 bg-blue-600 transform -translate-y-full group-hover:translate-y-0 transition-transform duration-300 ease-in-out"></div>
                <div className="relative z-10 transition-colors duration-300">
                  <div className="w-12 h-12 bg-blue-100 group-hover:bg-white/20 rounded-lg flex items-center justify-center mb-4">
                    <item.icon className="w-6 h-6 text-blue-600 group-hover:text-white" />
                  </div>
                  <h3 className="text-xl font-semibold text-gray-900 group-hover:text-white mb-2">
                    {item.title}
                  </h3>
                  <p className="text-gray-600 group-hover:text-white/80 mb-3">
                    {item.description}
                  </p>
                  <div className="flex items-center text-blue-600 group-hover:text-white font-medium">
                    <ArrowRight className="w-4 h-4 mr-2" />
                    {item.solution}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Features Grid */}
      <div className="py-20 bg-gray-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-16">
            <h2 className="text-3xl font-bold text-gray-900 mb-4">
              Your Complete Social Media Growth Solution
            </h2>
            <p className="text-xl text-gray-600">
              Everything you need to achieve exponential growth on social media
            </p>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {features.map((feature, index) => (
              <div 
                key={index}
                className="bg-white p-8 rounded-xl shadow-sm hover:shadow-md transition-shadow"
              >
                <div className="w-12 h-12 bg-blue-100 rounded-lg flex items-center justify-center mb-6">
                  <feature.icon className="w-6 h-6 text-blue-600" />
                </div>
                <h3 className="text-xl font-semibold text-gray-900 mb-3">
                  {feature.title}
                </h3>
                <p className="text-gray-600">
                  {feature.description}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>

           {/* Testimonials Section */}
           <div className="bg-gray-50 py-16 px-4 sm:px-6 lg:px-8">
        <div className="max-w-7xl mx-auto">
          <h2 className="text-3xl font-extrabold text-gray-900 text-center">What Our Customers Say</h2>
          <div className="mt-12 grid gap-8 lg:grid-cols-3">
            {testimonials.map((testimonial, index) => (
              <div key={index} className="bg-white shadow-lg rounded-lg p-8">
                <div className="flex items-center mb-4">
                  <StarIcon />
                  <StarIcon />
                  <StarIcon />
                  <StarIcon />
                  <StarIcon />
                </div>
                <p className="text-gray-600 mb-4">{testimonial.comment}</p>
                <div className="font-medium text-gray-900">{testimonial.name}</div>
                <div className="text-gray-500">{testimonial.role}</div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* CTA Section */}
      <div className="bg-blue-600 text-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-20">
          <div className="text-center">
            <h2 className="text-3xl font-bold mb-6">
              Ready to Transform Your Social Media Presence?
            </h2>
            <p className="text-xl opacity-90 mb-8">
              Join thousands of businesses achieving rapid growth with Socioblink
            </p>
            <button
              onClick={handleSignUp}
              className="px-8 py-4 bg-white text-blue-600 rounded-lg font-medium hover:bg-gray-100 transition-colors inline-flex items-center"
            >
              Start Your Free Trial
              <ArrowRight className="ml-2 w-5 h-5" />
            </button>
          </div>
        </div>
      </div>

      {/* Footer */}
    <footer className="bg-white py-12 border-t border-gray-100">
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
        <div>
          <h3 className="text-sm font-semibold text-gray-900 tracking-wider uppercase mb-4">
            Company
          </h3>
          <ul className="space-y-3">
            <li>
              <Link to="/about" className="text-base text-gray-500 hover:text-gray-900">
                About Us
              </Link>
            </li>
            <li>
              <Link to="/contact" className="text-base text-gray-500 hover:text-gray-900">
                Contact
              </Link>
            </li>
            <li>
              <Link to="/careers" className="text-base text-gray-500 hover:text-gray-900">
                Careers
              </Link>
            </li>
          </ul>
        </div>

        <div>
          <h3 className="text-sm font-semibold text-gray-900 tracking-wider uppercase mb-4">
            Product
          </h3>
          <ul className="space-y-3">
            <li>
              <Link to="/features" className="text-base text-gray-500 hover:text-gray-900">
                Features
              </Link>
            </li>
            <li>
              <Link to="/pricing" className="text-base text-gray-500 hover:text-gray-900">
                Pricing
              </Link>
            </li>
            {/* <li>
              <Link to="/integrations" className="text-base text-gray-500 hover:text-gray-900">
                Integrations
              </Link>
            </li> */}
          </ul>
        </div>

        <div>
          <h3 className="text-sm font-semibold text-gray-900 tracking-wider uppercase mb-4">
            Resources
          </h3>
          <ul className="space-y-3">
            <li>
              <Link to="/blog" className="text-base text-gray-500 hover:text-gray-900">
                Blog
              </Link>
            </li>
            <li>
              <Link to="/help" className="text-base text-gray-500 hover:text-gray-900">
                Help Center
              </Link>
            </li>
            {/* <li>
              <Link to="/guides" className="text-base text-gray-500 hover:text-gray-900">
                Guides
              </Link>
            </li> */}
          </ul>
        </div>

        <div>
          <h3 className="text-sm font-semibold text-gray-900 tracking-wider uppercase mb-4">
            Legal
          </h3>
          <ul className="space-y-3">
            <li>
              <Link to="/privacy" className="text-base text-gray-500 hover:text-gray-900">
                Privacy Policy
              </Link>
            </li>
            {/* <li>
              <Link to="/terms" className="text-base text-gray-500 hover:text-gray-900">
                Terms of Service
              </Link>
            </li>
            <li>
              <Link to="/security" className="text-base text-gray-500 hover:text-gray-900">
                Security
              </Link>
            </li> */}
          </ul>
        </div>
      </div>

      <div className="mt-8 pt-8 border-t border-gray-100">
        <p className="text-base text-gray-400">
          © {new Date().getFullYear()} Socioblink. All rights reserved.
        </p>
      </div>
    </div>
  </footer>

      {/* Font Style */}
      <style jsx global>{`
        @import url('https://fonts.googleapis.com/css2?family=Caveat:wght@700&display=swap');

        .font-handwritten {
          font-family: 'Caveat', cursive;
          font-size: 1.2em;
          padding: 0 0.2em;
        }
      `}</style>
    </div>
  );
};

export default HomePage;

