import React from 'react';
import { X as XIcon } from 'lucide-react';
import AIPostGeneratorModal from './AIPostGeneratorModal';

const PostInput = ({ 
  content, 
  onContentChange, 
  selectedImage,
  onImageChange,
  firstComment,
  onFirstCommentChange,
  showAIGenerator,
  onShowAIGenerator
}) => {
  const renderMediaPreview = () => {
    if (!selectedImage) return null;

    return (
      <div className="relative group mt-4">
        <img 
          src={selectedImage} 
          alt="Upload preview" 
          className="max-w-full h-auto rounded-lg"
        />
        <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity">
          <button
            onClick={() => onImageChange(null)}
            className="p-2 bg-white rounded-full hover:bg-gray-100"
            title="Remove media"
          >
            <XIcon className="w-4 h-4" />
          </button>
        </div>
      </div>
    );
  };

  const handleGeneratedPost = (post) => {
    onContentChange(post);
  };

  return (
    <div className="mb-6">
      <div className="min-h-[200px] border rounded-lg p-4">
        <textarea
          placeholder="What would you like to share?"
          value={content}
          onChange={(e) => onContentChange(e.target.value)}
          className="w-full h-full min-h-[120px] resize-none focus:outline-none"
        />
        
        {renderMediaPreview()}

        <AIPostGeneratorModal
          isOpen={showAIGenerator}
          onClose={() => onShowAIGenerator(false)}
          onSelectPost={handleGeneratedPost}
        />
      </div>
    </div>
  );
};

export default PostInput;