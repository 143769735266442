import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { UserProvider } from './UserContext';
import AuthUI from './AuthUI';
import Dashboard from './Dashboard';
import HomePage from './HomePage';
import Channels from './Channels';
import Calendar from './Calendar';
import ComingSoon from './ComingSoon';
import PrivacyPolicy from './PrivacyPolicy';
import BlogPage from './BlogPage';
import CareersPage from './CareersPage';
import ContactPage from './ContactPage';
import FeaturesPage from './FeaturesPage';
import AboutPage from './AboutPage';
import HelpCenter from './HelpCenter';
import PricingPage from './PricingPage';
import SubscriptionSuccess from './SubscriptionSuccess';

function App() {
  return (
    <UserProvider>
      <Router>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/auth" element={<AuthUI />} />
          <Route 
            path="/dashboard" 
            element={
              localStorage.getItem('token') 
                ? <Dashboard /> 
                : <Navigate to="/auth" replace />
            } 
          />
          <Route path="/calendar" 
          element={
              localStorage.getItem('token') 
                ? <Calendar /> 
                : <Navigate to="/auth" replace />
            } 
          />
          <Route 
            path="/channels" 
            element={
              localStorage.getItem('token') 
                ? <Channels /> 
                : <Navigate to="/auth" replace />
            } 
          />
          <Route 
            path="/analytics" 
            element={
              localStorage.getItem('token') 
                ? <ComingSoon /> 
                : <Navigate to="/auth" replace />
            } 
          />
          <Route 
            path="/ai-suggests" 
            element={
              localStorage.getItem('token') 
                ? <ComingSoon /> 
                : <Navigate to="/auth" replace />
            } 
          />
          <Route 
            path="/members" 
            element={
              localStorage.getItem('token') 
                ? <ComingSoon /> 
                : <Navigate to="/auth" replace />
            } 
          />
          <Route path="/upgrade" element={<PricingPage />} />
          <Route path="/pricing" element={<PricingPage />} />
          <Route path="/privacy" element={<PrivacyPolicy />} />
          <Route path="/blog" element={<BlogPage />} />
          <Route path="/careers" element={<CareersPage />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/features" element={<FeaturesPage />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/help" element={<HelpCenter />} />
          <Route path="/subscription/success" element={<SubscriptionSuccess />} />
        </Routes>
      </Router>
    </UserProvider>
  );
}

export default App;