import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { CheckCircle2, Loader2, AlertCircle, ArrowRight } from 'lucide-react';
import { Alert, AlertDescription } from './components/Alert';

const SubscriptionSuccess = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [status, setStatus] = useState('loading'); // loading, success, error
  const [subscriptionDetails, setSubscriptionDetails] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const verifySubscription = async () => {
      try {
        const sessionId = searchParams.get('session_id');
        
        if (!sessionId) {
          throw new Error('No session ID found');
        }

        // Verify the subscription with your backend
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/stripe/verify-subscription`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          },
          body: JSON.stringify({ sessionId })
        });

        if (!response.ok) {
          const error = await response.json();
          throw new Error(error.message || 'Failed to verify subscription');
        }

        const data = await response.json();
        setSubscriptionDetails(data);
        setStatus('success');

      } catch (err) {
        console.error('Subscription verification error:', err);
        setError(err.message);
        setStatus('error');
      }
    };

    verifySubscription();
  }, [searchParams]);

  const renderContent = () => {
    switch (status) {
      case 'loading':
        return (
          <div className="text-center">
            <div className="w-16 h-16 bg-blue-100 rounded-full flex items-center justify-center mx-auto mb-6">
              <Loader2 className="w-8 h-8 text-blue-600 animate-spin" />
            </div>
            <h2 className="text-2xl font-bold text-gray-900 mb-2">
              Verifying your subscription
            </h2>
            <p className="text-gray-600">
              Please wait while we confirm your payment...
            </p>
          </div>
        );

      case 'success':
        return (
          <div className="text-center">
            <div className="w-16 h-16 bg-green-100 rounded-full flex items-center justify-center mx-auto mb-6">
              <CheckCircle2 className="w-8 h-8 text-green-600" />
            </div>
            <h2 className="text-2xl font-bold text-gray-900 mb-2">
              Welcome to {subscriptionDetails?.plan} Plan!
            </h2>
            <p className="text-gray-600 mb-8">
              Your subscription has been successfully activated.
            </p>
            <div className="bg-gray-50 rounded-lg p-6 mb-8">
              <h3 className="text-lg font-semibold text-gray-900 mb-4">
                Subscription Details
              </h3>
              <dl className="grid grid-cols-1 gap-4">
                <div>
                  <dt className="text-sm text-gray-500">Plan</dt>
                  <dd className="text-sm font-medium text-gray-900">
                    {subscriptionDetails?.plan}
                  </dd>
                </div>
                <div>
                  <dt className="text-sm text-gray-500">Billing Period</dt>
                  <dd className="text-sm font-medium text-gray-900">
                    {subscriptionDetails?.billingPeriod}
                  </dd>
                </div>
                <div>
                  <dt className="text-sm text-gray-500">Next Billing Date</dt>
                  <dd className="text-sm font-medium text-gray-900">
                    {new Date(subscriptionDetails?.nextBillingDate).toLocaleDateString()}
                  </dd>
                </div>
              </dl>
            </div>
            <div className="space-y-4">
              <button
                onClick={() => navigate('/dashboard')}
                className="w-full px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors flex items-center justify-center"
              >
                Go to Dashboard
                <ArrowRight className="w-4 h-4 ml-2" />
              </button>
              <button
                onClick={() => navigate('/settings/subscription')}
                className="w-full px-4 py-2 bg-gray-900 text-white rounded-lg hover:bg-gray-800 transition-colors"
              >
                Manage Subscription
              </button>
            </div>
          </div>
        );

      case 'error':
        return (
          <div className="text-center">
            <div className="w-16 h-16 bg-red-100 rounded-full flex items-center justify-center mx-auto mb-6">
              <AlertCircle className="w-8 h-8 text-red-600" />
            </div>
            <h2 className="text-2xl font-bold text-gray-900 mb-2">
              Subscription Verification Failed
            </h2>
            {error && (
              <Alert variant="destructive" className="mb-6">
                <AlertDescription>{error}</AlertDescription>
              </Alert>
            )}
            <p className="text-gray-600 mb-8">
              Don't worry - if you completed the payment, your subscription will be
              activated automatically. Please contact support if you need assistance.
            </p>
            <div className="space-y-4">
              <button
                onClick={() => navigate('/dashboard')}
                className="w-full px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
              >
                Go to Dashboard
              </button>
              <button
                onClick={() => navigate('/contact-support')}
                className="w-full px-4 py-2 bg-gray-900 text-white rounded-lg hover:bg-gray-800 transition-colors"
              >
                Contact Support
              </button>
            </div>
          </div>
        );

      default:
        return null;
    }
  };

  return (
    <div className="min-h-screen bg-gray-50 py-12">
      <div className="max-w-lg mx-auto px-4">
        <div className="bg-white rounded-xl shadow-sm p-8">
          {renderContent()}
        </div>
      </div>
    </div>
  );
};

export default SubscriptionSuccess;