import React from 'react';
import { ArrowLeft } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

const PrivacyPolicy = () => {
  const navigate = useNavigate();

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Navigation */}
      <nav className="bg-white border-b border-gray-100">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between h-16">
            <div className="flex-shrink-0 flex items-center">
              <span className="text-2xl font-bold text-blue-600">Socioblink</span>
            </div>
            <button 
              onClick={() => navigate('/')}
              className="flex items-center text-gray-600 hover:text-gray-900"
            >
              <ArrowLeft className="w-5 h-5 mr-2" />
              Back to Home
            </button>
          </div>
        </div>
      </nav>

      {/* Content */}
      <div className="max-w-4xl mx-auto px-4 py-12">
        <div className="bg-white rounded-lg shadow-sm p-8">
          <h1 className="text-3xl font-bold text-gray-900 mb-8">Privacy Policy</h1>
          
          <section className="mb-8">
            <h2 className="text-2xl font-semibold text-gray-900 mb-4">Introduction</h2>
            <p className="text-gray-600 mb-4">
              Socioblink provides this Privacy Policy to inform you of our policies and procedures regarding the collection, 
              use, protection, and disclosure of Personal Information received from your use of our website and services. 
              This Privacy Policy also tells you about your rights and choices with respect to your Personal Information, 
              and how you can reach us to update your contact information or get answers to questions you may have about 
              our privacy practices.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold text-gray-900 mb-4">Personal Information We Collect</h2>
            <p className="text-gray-600 mb-4">
              For the purpose of this Privacy Policy, "Personal Information" means any information relating to an identified 
              or identifiable individual. We obtain Personal Information relating to you from various sources described below.
            </p>
            
            <h3 className="text-xl font-semibold text-gray-900 mt-6 mb-3">Information You Provide</h3>
            <ul className="list-disc pl-6 text-gray-600 space-y-2">
              <li>
                <strong>Registration</strong>: When you register for our service, we collect your name, email address, 
                password, and time zone.
              </li>
              <li>
                <strong>Social Media Accounts</strong>: When you connect your social media accounts, we may collect your 
                username, account IDs, social handles, and email address.
              </li>
              <li>
                <strong>Payment Information</strong>: When making payments, we collect credit card information and billing 
                address.
              </li>
              <li>
                <strong>Customer Support</strong>: We collect information through your communications with our support team.
              </li>
            </ul>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold text-gray-900 mb-4">Information from Connected Social Media Accounts</h2>
            
            <h3 className="text-xl font-semibold text-gray-900 mt-6 mb-3">LinkedIn</h3>
            <p className="text-gray-600 mb-4">When you connect your LinkedIn profile, we collect:</p>
            <ul className="list-disc pl-6 text-gray-600 space-y-2">
              <li>Profile image and display name</li>
              <li>Username/profile ID</li>
              <li>Access tokens</li>
              <li>Post content and engagement data (likes, shares, impressions)</li>
              <li>Profile analytics and insights</li>
            </ul>

            <h3 className="text-xl font-semibold text-gray-900 mt-6 mb-3">Instagram</h3>
            <p className="text-gray-600 mb-4">When you connect your Instagram profile, we collect:</p>
            <ul className="list-disc pl-6 text-gray-600 space-y-2">
              <li>Profile image and display name</li>
              <li>Username/profile ID</li>
              <li>Access tokens</li>
              <li>Post content and engagement data</li>
              <li>Profile analytics and audience data</li>
            </ul>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold text-gray-900 mb-4">How We Use Your Information</h2>
            <ul className="list-disc pl-6 text-gray-600 space-y-2">
              <li>Provide and maintain our services</li>
              <li>Process your payments and requests</li>
              <li>Post content to your social media accounts</li>
              <li>Analyze and improve our services</li>
              <li>Send service notifications and updates</li>
              <li>Provide customer support</li>
              <li>Monitor and analyze trends and usage</li>
              <li>Prevent fraud and enhance security</li>
              <li>Comply with legal obligations</li>
            </ul>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold text-gray-900 mb-4">Contact Us</h2>
            <p className="text-gray-600 mb-4">
              If you have questions about this Privacy Policy or our practices, please contact us at:
            </p>
            <div className="text-gray-600">
              <p>Email: privacy@socioblink.ai</p>
              <p>Socioblink Inc.</p>
              <p>[Address]</p>
              <p>[City, State, ZIP]</p>
            </div>
          </section>

          <div className="text-sm text-gray-500 mt-12">
            Effective Date: December 11, 2024
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;