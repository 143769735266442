// import React, { useRef, useState, useEffect } from 'react';
// import { 
//   Linkedin, 
//   Instagram, 
//   Clock,
//   ChevronLeft,
//   ChevronRight,
//   User
// } from 'lucide-react';
// import { useUser } from '../UserContext';

// const SocialMediaTabs = ({ channels, selectedChannel, onSelectChannel }) => {
//   const { user } = useUser();
//   const scrollContainerRef = useRef(null);
//   const [showLeftArrow, setShowLeftArrow] = useState(false);
//   const [showRightArrow, setShowRightArrow] = useState(false);

//   const checkForArrows = () => {
//     const container = scrollContainerRef.current;
//     if (container) {
//       const hasHorizontalScroll = container.scrollWidth > container.clientWidth;
//       setShowLeftArrow(container.scrollLeft > 0);
//       setShowRightArrow(
//         hasHorizontalScroll && container.scrollLeft < container.scrollWidth - container.clientWidth
//       );
//     }
//   };

//   useEffect(() => {
//     checkForArrows();
//   }, [channels]);

//   const scroll = (direction) => {
//     const container = scrollContainerRef.current;
//     if (container) {
//       const scrollAmount = direction === 'left' ? -300 : 300;
//       container.scrollBy({ left: scrollAmount, behavior: 'smooth' });
//       setTimeout(checkForArrows, 100);
//     }
//   };

//   const renderChannelContent = (channel) => {
//     const socialAccount = user?.socialAccounts?.[channel.toLowerCase()];
//     const isConnected = socialAccount?.isConnected;
//     const profileName = socialAccount?.username;
//     const profilePicture = socialAccount?.picture;

//     if (channel.toLowerCase() === 'linkedin' && isConnected && profileName) {
//       return (
//         <div className="flex items-center space-x-3">
//           <div className="relative">
//             {profilePicture ? (
//               <img 
//                 src={profilePicture} 
//                 alt={profileName}
//                 className="w-10 h-10 rounded-full border-2 border-white shadow-sm"
//                 onError={(e) => {
//                   e.target.onerror = null;
//                   e.target.src = `data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"/><circle cx="12" cy="7" r="4"/></svg>`;
//                 }}
//               />
//             ) : (
//               <div className="w-10 h-10 rounded-full bg-blue-100 flex items-center justify-center">
//                 <User className="w-6 h-6 text-blue-600" />
//               </div>
//             )}
//             <div className="absolute -bottom-0.5 -right-0.5 bg-white rounded-full p-0.5 shadow-sm">
//               <Linkedin className="w-4 h-4 text-[#0A66C2]" />
//             </div>
//           </div>
//           <span className="font-medium text-[#0A66C2] truncate max-w-[150px]">
//             {profileName}
//           </span>
//         </div>
//       );
//     }

//     // Default display for other platforms or when not connected
//     return (
//       <div className="flex items-center space-x-3">
//         {channel.toLowerCase() === 'instagram' && <Instagram className="h-5 w-5" />}
//         {channel.toLowerCase() === 'tiktok' && (
//           <svg 
//             className="h-5 w-5" 
//             viewBox="0 0 24 24" 
//             fill="currentColor"
//           >
//             <path d="M19.59 6.69a4.83 4.83 0 01-3.77-4.25V2h-3.45v13.67a2.89 2.89 0 01-5.2 1.74 2.89 2.89 0 112.31-4.64V9.32a6.32 6.32 0 00-1.37-.15A6.34 6.34 0 004 20.83a6.34 6.34 0 0010.86-4.43v-7a8.16 8.16 0 004.73 1.52v-3.4a4.85 4.85 0 01-1-.13z"/>
//           </svg>
//         )}
//         <span className="text-base font-medium">{channel}</span>
//       </div>
//     );
//   };

//   return (
//     <div className="relative">
//       {showLeftArrow && (
//         <button
//           onClick={() => scroll('left')}
//           className="absolute left-0 top-1/2 -translate-y-1/2 -ml-4 bg-white rounded-full p-1 shadow-md hover:bg-gray-50 z-10"
//         >
//           <ChevronLeft className="h-6 w-6 text-gray-600" />
//         </button>
//       )}
      
//       <div 
//         className="relative overflow-x-hidden"
//         style={{ margin: '0 20px' }}
//       >
//         <div
//           ref={scrollContainerRef}
//           className="flex space-x-4 overflow-x-auto scrollbar-hide"
//           onScroll={checkForArrows}
//         >
//           {channels.map((channel) => (
//             <button
//               key={channel}
//               onClick={() => onSelectChannel(channel)}
//               className={`
//                 flex items-center px-6 py-2.5 rounded-lg transition-colors min-w-[200px]
//                 ${selectedChannel === channel
//                   ? 'bg-white shadow-md'
//                   : 'bg-gray-100 hover:bg-gray-200'
//                 }
//               `}
//             >
//               {renderChannelContent(channel)}
//             </button>
//           ))}
//         </div>
//       </div>

//       {showRightArrow && (
//         <button
//           onClick={() => scroll('right')}
//           className="absolute right-0 top-1/2 -translate-y-1/2 -mr-4 bg-white rounded-full p-1 shadow-md hover:bg-gray-50 z-10"
//         >
//           <ChevronRight className="h-6 w-6 text-gray-600" />
//         </button>
//       )}
//     </div>
//   );
// };

// // Add this CSS to your global styles or as a style tag
// const ScrollbarHideStyles = `
//   .scrollbar-hide::-webkit-scrollbar {
//     display: none;
//   }
//   .scrollbar-hide {
//     -ms-overflow-style: none;
//     scrollbar-width: none;
//   }
// `;

// export default SocialMediaTabs;

import React, { useRef, useState, useEffect } from 'react';
import { 
  Linkedin, 
  Instagram, 
  Clock,
  ChevronLeft,
  ChevronRight,
  User
} from 'lucide-react';
import { useUser } from '../UserContext';

const SocialMediaTabs = ({ channels, selectedChannel, onSelectChannel }) => {
  const { user } = useUser();
  const scrollContainerRef = useRef(null);
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(false);

  const checkForArrows = () => {
    const container = scrollContainerRef.current;
    if (container) {
      const hasHorizontalScroll = container.scrollWidth > container.clientWidth;
      setShowLeftArrow(container.scrollLeft > 0);
      setShowRightArrow(
        hasHorizontalScroll && container.scrollLeft < container.scrollWidth - container.clientWidth
      );
    }
  };

  useEffect(() => {
    checkForArrows();
  }, [channels]);

  const scroll = (direction) => {
    const container = scrollContainerRef.current;
    if (container) {
      const scrollAmount = direction === 'left' ? -300 : 300;
      container.scrollBy({ left: scrollAmount, behavior: 'smooth' });
      setTimeout(checkForArrows, 100);
    }
  };

  const renderChannelContent = (channel) => {
    const socialAccount = user?.socialAccounts?.[channel.toLowerCase()];
    const isConnected = socialAccount?.isConnected;
    const profileName = socialAccount?.username;
    const profilePicture = socialAccount?.picture 

    if ((channel.toLowerCase() === 'linkedin' || channel.toLowerCase() === 'instagram') && isConnected && profileName) {
      return (
        <div className="flex items-center space-x-3">
          <div className="relative">
            {profilePicture ? (
              <img 
                src={profilePicture} 
                alt={profileName}
                className="w-10 h-10 rounded-full border-2 border-white shadow-sm"
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = `data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"/><circle cx="12" cy="7" r="4"/></svg>`;
                }}
              />
            ) : (
              <div className="w-10 h-10 rounded-full bg-blue-100 flex items-center justify-center">
                <User className="w-6 h-6 text-blue-600" />
              </div>
            )}
            <div className="absolute -bottom-0.5 -right-0.5 bg-white rounded-full p-0.5 shadow-sm">
              {channel.toLowerCase() === 'linkedin' ? (
                <Linkedin className="w-4 h-4 text-[#0A66C2]" />
              ) : (
                <Instagram className="w-4 h-4 text-[#E4405F]" />
              )}
            </div>
          </div>
          <span className={`font-medium truncate max-w-[150px] ${
            channel.toLowerCase() === 'linkedin' 
              ? 'text-[#0A66C2]' 
              : 'text-[#E4405F]'
          }`}>
            {profileName}
          </span>
        </div>
      );
    }

    // Default display for other platforms or when not connected
    return (
      <div className="flex items-center space-x-3">
        {channel.toLowerCase() === 'instagram' && <Instagram className="h-5 w-5" />}
        {channel.toLowerCase() === 'tiktok' && (
          <svg 
            className="h-5 w-5" 
            viewBox="0 0 24 24" 
            fill="currentColor"
          >
            <path d="M19.59 6.69a4.83 4.83 0 01-3.77-4.25V2h-3.45v13.67a2.89 2.89 0 01-5.2 1.74 2.89 2.89 0 112.31-4.64V9.32a6.32 6.32 0 00-1.37-.15A6.34 6.34 0 004 20.83a6.34 6.34 0 0010.86-4.43v-7a8.16 8.16 0 004.73 1.52v-3.4a4.85 4.85 0 01-1-.13z"/>
          </svg>
        )}
        <span className="text-base font-medium">{channel}</span>
      </div>
    );
  };

  return (
    <div className="relative">
      {showLeftArrow && (
        <button
          onClick={() => scroll('left')}
          className="absolute left-0 top-1/2 -translate-y-1/2 -ml-4 bg-white rounded-full p-1 shadow-md hover:bg-gray-50 z-10"
        >
          <ChevronLeft className="h-6 w-6 text-gray-600" />
        </button>
      )}
      
      <div 
        className="relative overflow-x-hidden"
        style={{ margin: '0 20px' }}
      >
        <div
          ref={scrollContainerRef}
          className="flex space-x-4 overflow-x-auto scrollbar-hide"
          onScroll={checkForArrows}
        >
          {channels.map((channel) => (
            <button
              key={channel}
              onClick={() => onSelectChannel(channel)}
              className={`
                flex items-center px-6 py-2.5 rounded-lg transition-colors min-w-[200px]
                ${selectedChannel === channel
                  ? 'bg-white shadow-md'
                  : 'bg-gray-100 hover:bg-gray-200'
                }
              `}
            >
              {renderChannelContent(channel)}
            </button>
          ))}
        </div>
      </div>

      {showRightArrow && (
        <button
          onClick={() => scroll('right')}
          className="absolute right-0 top-1/2 -translate-y-1/2 -mr-4 bg-white rounded-full p-1 shadow-md hover:bg-gray-50 z-10"
        >
          <ChevronRight className="h-6 w-6 text-gray-600" />
        </button>
      )}
    </div>
  );
};

// Add this CSS to your global styles or as a style tag
const ScrollbarHideStyles = `
  .scrollbar-hide::-webkit-scrollbar {
    display: none;
  }
  .scrollbar-hide {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
`;

export default SocialMediaTabs;